import React from 'react';
import './App.scss';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Resume } from './ui-components/legacy/resume';
import { ExtendedResume } from './ui-components/legacy/extended-resume';


export const App = () => (
	<Router>
		<Switch>
			<Route exact path="/">
				<ExtendedResume />
			</Route>
			<Route exact path="/resume">
				<Resume />
			</Route>
		</Switch>
	</Router>
)

export default App;
