import React from 'react';
import styles from './resume.module.scss';

export const Resume = () => {
	return (
		<>
			<div className={styles.body}>
				<div className={styles.mainDetails}>
					<div className={styles.headerSection}>
						<h1 className={`${styles.quickFade} ${styles.delayTwo} ${styles.name}`}>Benjamin Baacke</h1>
						<h2 className={`${styles.quickFade} ${styles.delayThree} ${styles.title}`}>Software Engineer</h2>
					</div>

					{/* <span className={styles.clear}></span> */}

					<div id={styles.contactDetails} className={`${styles.quickFade} ${styles.delayFour}`}>
						<ul>
							{/* <!-- TODO: update to make them look like links --> */}
							<li style={{ textAlign: "right" }}>
								<a href="tel:+1-614-582-2729">(614) 582-2729</a>
							</li>
							<li style={{ textAlign: "right" }}>
								<a href="mailto:ben.baacke@gmail.com" target="_blank" rel="noreferrer">ben.baacke@gmail.com</a>
							</li>
						</ul>
					</div>
					<div className="clear"></div>
				</div>

				{/* TODO: update the following to be accurate and proof read */}
				<div className={`${styles.quickFade} ${styles.delayFive} ${styles.mainArea}`}>

					<section>
						<div className={styles.sectionTitle}>
							<h1>Education</h1>
						</div>

						<div className={styles.sectionContent}>
							<article>
								<h2 style={{float:"left"}}>Miami University</h2>
								<p className={styles.educationSubDetails}>Bachelors of Science in Computer Science</p>
								<ul className={styles.educationDetails}>
									<li>Graduated Cum Laude</li>
									<li>Recognized on both the President's and Dean's lists</li>
									<li>GPA: 3.62</li>
								</ul>
								{/* <p className={styles.educationDetails}>Recognized on both the President's and Dean's lists</p> */}
							</article>
						</div>
						<div className="clear"></div>
					</section>

					<section>
						<div className={styles.sectionTitle}>
							<h1>Work Experience</h1>
						</div>

						<div className={styles.starttest}>
							{/* TODO: Add the machine learning competition
							TODO: add project now */}
						</div>
						<div className={styles.sectionContent}>
							<article>
								<h2>Software Engineer at Paycor</h2>
								<p className={styles.subDetails}>June 2019 - Present</p>

								{/* 
									- new reporting application from the ground up
									- React, TypeScript, C# .NET Core
									- SQL Server, Snowflake
									- led an initiative to integrate a third party app
										- JavaScript + AJAX
										- C#
									- Fully Agile Team
								*/}
								<ul>
									<li>Assisted in building a new reporting application from the ground up using React, TypeScript and Redux</li>
									<li>Built RESTful APIs using C# .NET Core</li>
									<li>Wrote SQL Server and Snowflake queries</li>
									<li>Led an initiative to integrate a third party app using JavaScript and AJAX to create and interact with an iframe</li>
									<li>Worked with a fully Agile team</li>
								</ul>
							</article>

							<article>
								<h2>Software Engineer Intern at Paycor</h2>
								<p className={styles.subDetails}>May 2018 - August 2018</p>

								{/* 
								    • Currently working on the Reporting Application
									• Working with a C# Web Application connected to a SQL Server database
									• Participating in AGILE development
								*/}
								<ul>
									{/* TODO */}
									{/* <li>Data warehouse data descrepencies between source and target with Excel output</li> */}
									<li>Developed C# application to find data discrepancies between the source and the destination that would email an excel output to another team to fix any issues</li>
									<li>Worked with a large scale C# Web Application that clients used for reporting</li>
									<li>Participated in AGILE development</li>
								</ul>
								{/* <p>
									I developed a C# application that ran a SQL Query to find the differences in data
									between two tables. It output an excel file with the data differences, and then emailed
									that file to a different team to fix any issues.
                            </p> */}
							</article>

							<article>
								<h2>Teacher's Assistant for Miami University's Computer Science Department</h2>
								<p className={styles.subDetails}>September 2017 - May 2018</p>
								<ul>
									<li>Assisted student learning through help sessions and labs</li>
									<li>Graded student homework and labs</li>
								</ul>
							</article>

							<article>
								<h2>Agency Solutions Intern at Nationwide Insurance</h2>
								<p className={styles.subDetails}>May - August 2017</p>
								<ul>
									<li>Crafted a MicroStrategy report using Teradata SQL to discover discrepancies in data</li>
									<li>Wrote Python scripts to increase team efficiency</li>
								</ul>
							</article>

							{/* <article>
								<h2>Desktop Service Project Team Intern at Nationwide Children's Hospital</h2>
								<p className={styles.subDetails}>May - August 2016</p>
								<ul>
									<li>Built, imaged, and deployed PCs to support hospital staff</li>
									<li>Assisted moving over 700 PCs into a new office building</li>
									<li>Sharpened teamwork and communication skills</li>
								</ul>
							</article> */}
						</div>
						<div className="clear"></div>
					</section>

					<section>
						<div className={styles.sectionTitle}>
							<h1>Other Projects</h1>
						</div>
						<div className={styles.sectionContent}>
							<article>
								<h2>AWS Autonomous Car Challenge Champion</h2>
								<p className={styles.subDetails}>March 2019</p>
								<ul>
									<li>Used AWS machine learning models to train a self driving car</li>
									<li>Out of 4 teams, my team of 4 ran the fastest lap</li>
								</ul>
							</article>

							<article>
								<h2>Project NOW</h2>
								<p className={styles.subDetails}>2018 - 2019</p>
								<ul>
									<li>Designed and built a web app to give aspiring programmers project ideas and real world experience</li>
									<li>Worked on a team of 4 using C# .NET Framework, SQL, and JavaScript</li>
								</ul>
							</article>
						</div>
					</section>

					<section>
						<div className={styles.sectionTitle}>
							<h1>Programming Skills</h1>
						</div>

						<div className={`${styles.sectionContent} ${styles.centered}`}>
							<ul className={styles.keySkills}>
								<li>React</li>
								<li>Redux</li>
								<li>C#</li>
								<li>SQL</li>
								<li>JavaScript & TypeScript</li>
								<li>Snowflake</li>
							</ul>
						</div>
						<div className="clear"></div>
					</section>

					<section>
						<div className={styles.sectionTitle}>
							<h1>Tools</h1>
						</div>

						<div className={`${styles.sectionContent} ${styles.centered}`}>
							<ul className={styles.keySkills}>
								<li>Visual Studio</li>
								<li>SQL Server Management Studio</li>
								<li>Azure Data Studio</li>
								<li>git</li>
								<li>Visual Studio Code</li>
								<li>Microsoft TFS</li>
							</ul>
						</div>
						<div className="clear"></div>
					</section>
				</div>
			</div>
		</>
	);
}