import React from 'react';
import headshot from '../../images/headshot.jpg';
import { useHistory } from "react-router";
import styles from './extended-resume.module.scss';

export const ExtendedResume = () => {
  const history = useHistory();

  const handleResumeClick = () => {
    history.push("/resume");
  }

  return (
    <>
      <div id="cv" className="instaFade">
        <div className="mainDetails">
          <div id="headshot" className="quickFade">
            <img src={headshot} alt="Benjamin Baacke" />
          </div>

          <div id="name">
            <h1 className="quickFade delayTwo">Benjamin Baacke</h1>
            <h2 className="quickFade delayThree">Software Engineer</h2>
          </div>

          <div id="contactDetails" className="quickFade delayFour">
            <ul>
              {/* <!-- TODO: update to make them look like links --> */}
              <li style={{ textAlign: "right" }}>
                <a href="tel:+1-614-582-2729">(614) 582-2729</a>
              </li>
              <li style={{ textAlign: "right" }}>
                <a href="mailto:ben.baacke@gmail.com" target="_blank" rel="noreferrer">ben.baacke@gmail.com</a>
              </li>
              <li style={{ textAlign: "right" }}>
                <a href="https://www.linkedin.com/in/benjamin-baacke" target="_blank" rel="noreferrer">LinkedIn</a>
              </li>
              <li style={{ textAlign: "right" }}>
                <button className={styles.links} onClick={handleResumeClick}>Printer Friendly Resume</button>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
        </div>

        <div id="mainArea" className="quickFade delayFive">
          {/* TODO: add car and senior project */}
          {/* https://www.miamioh.edu/cec/news/2019/03/amazon-robocars.html */}
          <section>
            <article>
              <div className="sectionTitle">
                <h1>Personal Profile</h1>
              </div>

              <div className="sectionContent">
                <p>
                  I am a passionate software engineer working at Paycor, a human capital management software
                  company. I have been working in the
                  Reporting and Analytics department for almost two years, and I also interned for 3 months
                  before I graduated.
                            </p>
                <p>
                  I love doing all sorts of work related to technology and computers. I have worked at both
                  Nationwide Insurance and
                  Nationwide Children's Hospital in their IT departments, and I have also worked with
                  technology outside of a professional
                  environment. Most of my experience is with React, C#, and SQL, but I love to learn new
                  technologies. I have built and upgraded my own computer, and I have built
                  computers for both friends and family. I have
                  programming experience in many languages including
                  JavaScript (TypeScript), C#, SQL, and Snowflake.
                            </p>
                <p>
                  Outside of work, I enjoy golfing, biking, and anything related to cars. I'm a big fan of
                  motorsports such as NASCAR, and I also
                  race my own car in autocross events.
                            </p>
              </div>
            </article>
            <div className="clear"></div>
          </section>

          <section>
            <div className="sectionTitle">
              <h1>Programming Skills</h1>
            </div>

            <div className="sectionContent">
              <ul className="keySkills">
                <li>React</li>
                <li>Redux</li>
                <li>C#</li>
                <li>SQL</li>
                <li>JavaScript & TypeScript</li>
                <li>Snowflake</li>
              </ul>
            </div>
            <div className="clear"></div>
          </section>

          <section>
            <div className="sectionTitle">
              <h1>Work Experience</h1>
            </div>

            <div className="sectionContent">
              <article>
                <h2>Software Engineer at Paycor</h2>
                <p className="subDetails">June 2019 - Present</p>
                <p>
                  I am developing a new reporting platform from the ground up. The front end uses React with
                  TypeScript, and talks to a C# .NET Core back end.
                  The C# back end uses a SQL Server Database, and also accesses a Snowflake data warehouse to
                  generate reports.
                            </p>
                <p>
                  I led an initiative to integrate a third party app using JavaScript and AJAX to build an
                  iframe. I built an API to assist
                  with transferring data and creating/updating users in the third party system.
                            </p>
                <p>
                  I use Visual Studio Code, Visual Studio Professional, Azure Data Studio, SQL Server
                  Management Studio, along with git and other tools.
                  My team worked in a full AGILE environment using scrum methodologies.
                            </p>
              </article>

              <article>
                <h2>Software Engineer Intern at Paycor</h2>
                <p className="subDetails">May 2018 - August 2018</p>
                <p>
                  I developed a C# application that ran a SQL Query to find the differences in data
                  between two tables. It output an excel file with the data differences, and then emailed
                  that file to a different team to fix any issues.
                            </p>
              </article>

              <article>
                <h2>Teacher's Assistant for Miami University's Computer Science Department</h2>
                <p className="subDetails">September 2017 - May 2018</p>
                <p>
                  I was responsible for running help sessions, assisting during labs, and grading assignments
                  for the Introduction to Computer Science className. During the help sessions and labs, I
                  assisted students
                  with their questions. During the labs, I also helped the students understand the material
                  that the professor taught in lecture.
                            </p>
              </article>

              <article>
                <h2>Agency Solutions Intern at Nationwide Insurance</h2>
                <p className="subDetails">May - August 2017</p>
                {/* <!--
    
                            ● Managed application that calculates compensation for agents
                            ● Created MicroStrategy report to discover discrepancies in data
                            ● Wrote Python scripts to increase team efficiency
                            ● Crafted Teradata SQL queries to debug MicroStrategy data issues
                            ● Worked in an AGILE team environment
    
                        --> */}
                <p>
                  I created a MicroStrategy report to discover discrepancies in the agent compensation data.
                  This report helped my team to find and fix problems quickly. I crafted Teradata SQL queries
                  while I was working on this report to help me debug any errors.
                            </p>
                <p>
                  I also wrote Python scripts to find the most used database schemas and tables. This data
                  helped my team create Disaster Recover documentation.
                            </p>
              </article>

              <article>
                <h2>Desktop Service Project Team Intern at Nationwide Children's Hospital</h2>
                <p className="subDetails">May - August 2016</p>
                {/* <!--
                            ● Built, imaged, and deployed PCs to support hospital staff
                            ● Assisted moving over 700 PCs into a new office building
                            ● Sharpened teamwork and communication skills
                        --> */}
                <p>
                  I built, imaged, and deployed PCs to support hospital staff. I also assisted in moving over
                  700 PCs into a new office building. As an intern at Nationwide Children's Hospital, I was
                  able to sharpen my soft skills such as teamwork and communication.
                            </p>
              </article>


            </div>
            <div className="clear"></div>
          </section>

          <section>
            <div className="sectionTitle">
              <h1>Education</h1>
            </div>

            <div className="sectionContent">
              <article>
                <h2>Miami University</h2>
                <p className="subDetails">Bachelors of Science in Computer Science</p>
                <p>
                  During my career at Miami University, I was recognized on both the Dean's List and the President's List.
                            </p>
              </article>
            </div>
            <div className="clear"></div>
          </section>

        </div>
      </div>
    </>
  );
}